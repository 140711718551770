<template>
  <Header title="Compensation Strategies" />

  <Container>
    <h2>Compensation Strategies for Key Executives</h2>
    <p>
      For a successful business owner, competent, satisfied executives are
      vital. The business relies on these people to handle its operations. The
      owner needs to free up his or her time for strategic planning and
      creativity.
      <br /><br />
      Our clients recognize that compensating these individuals is essential to
      attracting, retaining and motivating quality management.
      <br /><br />
      At Bryan and Hedden, we work with our clients to creatively structure
      executive benefit programs designed to address these issues. Such programs
      may include:
    </p>
    <ul class="space-y-5 mt-4 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Executive retention strategies</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Profit sharing plans</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Pension plans</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Retirement compensation arrangements</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Executive income continuation plans</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Other tax deferral arrangements</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Tax assisted enhanced benefit options</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Health spending accounts</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Custom designed executive benefits</p></li>
    </ul>
    <p class="mt-6">
      For higher income earners, salary increases become increasingly less
      effective as motivation, due to excessive taxation. Many business owners
      turn to alternate compensation methods to ensure that their key people are
      receiving the best possible compensation arrangements. Bryan and Hedden
      can help achieve these results.
    </p>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Container,
    Header,
  },
};
</script>
